import React, { useEffect, useRef } from 'react';
import './marketVotingShares.css';
import Share1 from "../../images/votingShare1.png";
import Share2 from "../../images/votingShare2.png";
import Aos from "aos";
import "aos/dist/aos.css";

export default function MarketVotingShares() {
    const incrementRef1 = useRef(null);
    const incrementRef2 = useRef(null);
    const incrementRef3 = useRef(null);
    const incrementRef4 = useRef(null);
    const incrementRef5 = useRef(null);
    const incrementRef6 = useRef(null);

    useEffect(() => {
        const animateNumber = (ref, end, duration, decimals = 0) => {
            let start = 0;
            const increment = end / (duration / 16);

            const animate = () => {
                start += increment;
                if (ref.current) {
                    if (start < end) {
                        ref.current.textContent = start.toFixed(decimals).toLocaleString();
                        requestAnimationFrame(animate);
                    } else {
                        ref.current.textContent = end.toFixed(decimals).toLocaleString();
                    }
                }
            };

            requestAnimationFrame(animate);
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    animateNumber(incrementRef1, 291_600_000, 2000, 0);
                    animateNumber(incrementRef2, 75.9, 2000, 1);
                    animateNumber(incrementRef3, 22.1, 2000, 1);
                    animateNumber(incrementRef4, 87480000, 2000, 0);
                    animateNumber(incrementRef5, 57.9, 2000, 1);
                    animateNumber(incrementRef6, 5.1, 2000, 1);
                    observer.disconnect(); 
                }
            });
        });

        if (incrementRef1.current) {
            observer.observe(incrementRef1.current);
        }

        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);

  return (
    <div className='marketVotingShare'>
        <div className="marketVoting-two-column-container">
            <div className="marketVoting-column" data-aos="zoom-in" data-aos-duration="1000">
                <div className="marketVoting-image-container">
                    <div className="marketVoting-overlay"></div>
                    <img src={Share1} alt="Image 1" className="marketVoting-responsive-image" />
                    <div className="marketVoting-overlay-text">
                        <h1 className='marketVoting-h1'>Voting Shares</h1>
                        <p className='marketVoting-para1'>No. of Shares</p>
                        <span ref={incrementRef1} className="marketVoting-overlay-number1">000,000,000</span>
                        <p className='marketVoting-para2'>Share Price</p>
                        <span ref={incrementRef2} className="marketVoting-overlay-number2">00.0</span>
                        <p className='marketVoting-para3'>Current Market Capitalization</p>
                        <span ref={incrementRef3} className="marketVoting-overlay-number3">00.0</span>
                    </div>
                </div>
            </div>
            <div className="marketVoting-column" data-aos="zoom-in" data-aos-duration="1000">
                <div className="marketVoting-image-container">
                    <div className="marketVoting-overlay"></div>
                    <img src={Share2} alt="Image 2" className="marketVoting-responsive-image" />
                    <div className="marketVoting-overlay-text">
                        <h1 className='marketVoting-h1'>Non-Voting Shares</h1>
                        <p className='marketVoting-para1'>No. of Shares</p>
                        <span ref={incrementRef4} className="marketVoting-overlay-number1">0</span>
                        <p className='marketVoting-para2'>Share Price</p>
                        <span ref={incrementRef5} className="marketVoting-overlay-number2">0</span>
                        <p className='marketVoting-para3'>Current Market Capitalization</p>
                        <span ref={incrementRef6} className="marketVoting-overlay-number3">0</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

import React, { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import './marketCapitalGraph1.css';
import Aos from "aos";
import "aos/dist/aos.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const SplineChart = () => {
  const [chart1Animation, setChart1Animation] = useState(false);
  const [chart2Animation, setChart2Animation] = useState(false);

  const chart1Ref = useRef(null);
  const chart2Ref = useRef(null);

  const data1 = {
    labels: ['2017/18', '2018/19', '2019/20', '2020/21', '2021/22', '2022/23', '2023/24', '2024/25'],
    datasets: [
      {
        data: [3000, 2800, 2600, 15000, 10000, 21000, 27000, 24000],
        fill: false,
        backgroundColor: 'rgba(255, 255, 255)',
        borderColor: 'rgba(255, 255, 255)',
        tension: 0.4,
      },
    ],
  };

  const data2 = {
    labels: ['2017/18', '2018/19', '2019/20', '2020/21', '2021/22', '2022/23', '2023/24', '2024/25'],
    datasets: [
      {
        data: [1000, 800, 500, 3500, 2600, 4000, 8300, 5500],
        fill: false,
        backgroundColor: 'rgba(255, 255, 255)',
        borderColor: 'rgba(255, 255, 255)',
        tension: 0.4,
      },
    ],
  };

  const options1 = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      title: { display: true, text: 'Rs. Mn' },
    },
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: 30000,
        ticks: {
          stepSize: 3000,
          font: { size: 10 },
        },
      },
    },
  };

  const options2 = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      title: { display: true, text: 'Rs. Mn' },
    },
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: 9000,
        ticks: {
          stepSize: 900,
          font: { size: 10 },
        },
      },
    },
  };

  useEffect(() => {
    const observerOptions = {
      threshold: 0.5, 
    };

    const chart1Observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setChart1Animation(true);
        }
      },
      observerOptions
    );

    const chart2Observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setChart2Animation(true);
        }
      },
      observerOptions
    );

    if (chart1Ref.current) {
      chart1Observer.observe(chart1Ref.current);
    }

    if (chart2Ref.current) {
      chart2Observer.observe(chart2Ref.current);
    }

    return () => {
      if (chart1Ref.current) {
        chart1Observer.unobserve(chart1Ref.current);
      }
      if (chart2Ref.current) {
        chart2Observer.unobserve(chart2Ref.current);
      }
    };
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="CapitalGraph1-charts-container">
      <div className="CapitalGraph1-lineChart" ref={chart1Ref} data-aos="fade-right" data-aos-duration="900">
        <div className="CapitalGraph1-voting-chart">
          <div className="CapitalGraph1-chart-container">
            <h1 className="CapitalGraph1-chart-container-h1">Market Capitalization</h1>
            <p>Voting Share Price</p>
            {chart1Animation && <Line data={data1} options={options1} />}
          </div>
        </div>
      </div>
      <div className="CapitalGraph1-lineChart" ref={chart2Ref} data-aos="fade-left" data-aos-duration="900">
        <div className="CapitalGraph1-voting-chart">
          <div className="CapitalGraph1-chart-container">
            <h1 className="CapitalGraph1-chart-container-h1">Market Capitalization</h1>
            <p>Non-Voting Share Price</p>
            {chart2Animation && <Line data={data2} options={options2} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SplineChart;

import React from "react";
import "./marketCapitalMainBanner.css";
import Image from "../../images/MarketCapitalMainBanner.png";

export default function MarketCapitalMainBanner() {
  return (
    <div>
      <div style={{ position: "relative"}}>
        <img className="marketCapital-home-image" alt="" src={Image} />
        <div className="marketCapital-image-text-box">
          <div className="marketCapital-image-background-text">
            <h1 className="marketCapital-image-topic">Market Capitalization</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import MarketCapitalMainBanner from "../templates/MarketCapitalization/marketCapitalMainBanner";
import MarketCapitalBlueBar from "../templates/MarketCapitalization/marketCapitalBlueBar";
import MarketVotingShares from "../templates/MarketCapitalization/marketVotingShares";
import SplineChart from "../templates/MarketCapitalization/marketCapitalGraph1";

export default function MarketCapitalization() {
  return (
    <div>
      <MarketCapitalMainBanner />
      <MarketCapitalBlueBar />
      <MarketVotingShares />
      <SplineChart />
    </div>
  );
}

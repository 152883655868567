import React from 'react'
import './marketCapitalBlueBar.css'

export default function MarketCapitalBlueBar() {
  return (
    <div className='blueBar'>
      <div className='blueBarContainer'>
        <div className='blueBarContent'>
            <h2>
                Market Capitalization = No. of Shares x Share Price
            </h2>
        </div>
      </div>
    </div>
  )
}
